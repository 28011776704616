import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: true,
  newProduct: [],
  error: "",
};

export const getProducts = createAsyncThunk(
  "newProducts/getNewProducts",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const fetchNewProducts = await axios.get(
        "http://hashemceramic.com/marble/products_api"
      );
      const data = await fetchNewProducts.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const newProductSlice = createSlice({
  name: "newProducts",
  initialState,
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.loading = true;
      state.error = "";
    },
    [getProducts.fulfilled]: (state, action) => {
      state.loading = false;
      state.newProduct = action.payload;
    },
    [getProducts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default newProductSlice.reducer;
