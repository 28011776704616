import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: true,
  advice: [],
  error: "",
};

export const getAdvice = createAsyncThunk(
  "advice/getadvice",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const fetchAdvice = await axios.get(
        "http://hashemceramic.com/marble/advice_api"
      );
      const data = await fetchAdvice.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const adviceSlice = createSlice({
  name: "advice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAdvice.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAdvice.fulfilled, (state, action) => {
      state.loading = false;
      state.advice = action.payload;
    });
    builder.addCase(getAdvice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default adviceSlice.reducer;
