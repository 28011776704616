import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: true,
  ourDesigns: [],
  error: "",
};

export const getOurDesign = createAsyncThunk(
  "ourDesgin/getOurDesign",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const fetchOurDesign = await axios.get(
        "http://hashemceramic.com/marble/ourdesigns_api"
      );
      const data = await fetchOurDesign.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const ourDesignSlice = createSlice({
  name: "ourDesgin",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getOurDesign.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getOurDesign.fulfilled, (state, action) => {
      state.loading = false;
      state.ourDesigns = action.payload;
    });
    builder.addCase(getOurDesign.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default ourDesignSlice.reducer;
