import React, { useEffect, useState } from 'react'
import MainTitle from '../MainTitle/MainTitle'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useLang } from '../../context/language';
import { useDispatch, useSelector } from 'react-redux';
import { getBranches } from '../../redux/branchesSlice';
import Spinner from '../Spinner/Spinner';

const Branches = () => {
    const { lang } = useLang()
    const { home } = useSelector(state => state.home)
    const { loading, branches } = useSelector(state => state.branches)
    const dispatch = useDispatch()
    const [state, setState] = useState(false)

    //Owl Carousel Settings
    const options = {
        loop: true,
        margin: 0,
        dots: false,
        responsiveClass: true,
        nav: false,
        autoplay: true,
        smartSpeed: 1000,
        lazyLoad: true,
        responsive: {
            0: {
                items: 1,
            },
            578: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 4,
            },
            1200: {
                items: 5,
            },
        },
    };

    useEffect(() => {
        branches.length === 0 && dispatch(getBranches())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branches])

    useEffect(() => {
        setState(lang)
    }, [lang])
    return (
        <section className='branches' id='brunches' states={`${state}`}>
            <div className="container">
                {
                    <MainTitle title={lang ? home[0]?.ar[0]?.branches_title : home[0]?.en[0]?.branches_title}
                        description={lang ? home[0]?.ar[0]?.branches_desc : home[0]?.en[0]?.branches_desc} />
                }
            </div>
            {
                loading ? <Spinner />
                    :
                    <OwlCarousel className="slider-items owl-carousel" {...options}>
                        {
                            branches.map(baranch => <div className="item" key={baranch.id}>
                                <img src={`http://hashemceramic.com/${baranch.img}`} alt="" />
                                <div className="text">
                                    <div className="content" style={{ direction: lang ? 'rtl' : '' }}>
                                        <h4 className="title">{lang ? baranch?.ar[0]?.title : baranch?.en[0]?.title}</h4>
                                        <h6>
                                            <div className="icon">
                                                <svg id="marker-svgrepo-com_2_" data-name="marker-svgrepo-com (2)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.374 22">
                                                    <path id="Path_27613" data-name="Path 27613" d="M30.986,10.417c-5.076,0-9.187,3.509-9.187,7.837a7.082,7.082,0,0,0,1.825,4.689l5.893,8.707a1.423,1.423,0,0,0,.11.163l.013.019,0,0a1.83,1.83,0,0,0,2.6.087l.015.008.058-.086a1.42,1.42,0,0,0,.217-.321l5.773-8.531a7.091,7.091,0,0,0,1.865-4.733C40.173,13.926,36.062,10.417,30.986,10.417ZM30.9,22.227c-2.5,0-4.527-1.729-4.527-3.861S28.394,14.5,30.9,14.5s4.527,1.729,4.527,3.861S33.4,22.227,30.9,22.227Z" transform="translate(-21.799 -10.417)" fill="#c99855" />
                                                </svg>
                                            </div>
                                            {lang ? baranch?.ar[0]?.location : baranch?.en[0]?.location}
                                        </h6>
                                    </div>
                                </div>
                            </div>)
                        }
                        {/* <div className="item">
                    <img src="/imgs/branche-2.png" alt="" />
                    <div className="text">
                        <div className="content">
                            <h4 className="title">Name Of Brunch</h4>
                            <h6>
                                <div className="icon">
                                    <svg id="marker-svgrepo-com_2_" data-name="marker-svgrepo-com (2)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.374 22">
                                        <path id="Path_27613" data-name="Path 27613" d="M30.986,10.417c-5.076,0-9.187,3.509-9.187,7.837a7.082,7.082,0,0,0,1.825,4.689l5.893,8.707a1.423,1.423,0,0,0,.11.163l.013.019,0,0a1.83,1.83,0,0,0,2.6.087l.015.008.058-.086a1.42,1.42,0,0,0,.217-.321l5.773-8.531a7.091,7.091,0,0,0,1.865-4.733C40.173,13.926,36.062,10.417,30.986,10.417ZM30.9,22.227c-2.5,0-4.527-1.729-4.527-3.861S28.394,14.5,30.9,14.5s4.527,1.729,4.527,3.861S33.4,22.227,30.9,22.227Z" transform="translate(-21.799 -10.417)" fill="#c99855" />
                                    </svg>
                                </div>
                                location type here
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <img src="/imgs/branche-3.png" alt="" />
                    <div className="text">
                        <div className="content">
                            <h4 className="title">Name Of Brunch</h4>
                            <h6>
                                <div className="icon">
                                    <svg id="marker-svgrepo-com_2_" data-name="marker-svgrepo-com (2)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.374 22">
                                        <path id="Path_27613" data-name="Path 27613" d="M30.986,10.417c-5.076,0-9.187,3.509-9.187,7.837a7.082,7.082,0,0,0,1.825,4.689l5.893,8.707a1.423,1.423,0,0,0,.11.163l.013.019,0,0a1.83,1.83,0,0,0,2.6.087l.015.008.058-.086a1.42,1.42,0,0,0,.217-.321l5.773-8.531a7.091,7.091,0,0,0,1.865-4.733C40.173,13.926,36.062,10.417,30.986,10.417ZM30.9,22.227c-2.5,0-4.527-1.729-4.527-3.861S28.394,14.5,30.9,14.5s4.527,1.729,4.527,3.861S33.4,22.227,30.9,22.227Z" transform="translate(-21.799 -10.417)" fill="#c99855" />
                                    </svg>
                                </div>
                                location type here
                            </h6>
                        </div>
                    </div>
                </div> */}
                    </OwlCarousel>
            }
        </section>
    )
}

export default Branches