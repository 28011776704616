import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: true,
  home: [],
  error: "",
};

export const getHome = createAsyncThunk("home/getHome", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const fetchHome = await axios.get(
      "http://hashemceramic.com/marble/home_api"
    );
    const data = await fetchHome.data;
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const homeApiSlice = createSlice({
  name: "home",
  initialState,
  extraReducers: {
    [getHome.pending]: (state) => {
      state.loading = true;
      state.error = "";
    },
    [getHome.fulfilled]: (state, action) => {
      state.loading = false;
      state.home = action.payload;
    },
    [getHome.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default homeApiSlice.reducer;
