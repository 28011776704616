import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: true,
  faqs: [],
  error: "",
};

export const getFaqs = createAsyncThunk("faq/getFaq", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const fetchFaqs = await axios.get(
      "http://hashemceramic.com/marble/faq_api"
    );
    const data = await fetchFaqs.data;
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const faqSlice = createSlice({
  name: "faq",
  initialState,
  extraReducers: {
    [getFaqs.pending]: (state) => {
      state.loading = true;
      state.error = "";
    },
    [getFaqs.fulfilled]: (state, action) => {
      state.loading = false;
      state.faqs = action.payload;
    },
    [getFaqs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default faqSlice.reducer;
