import React from 'react'
import { useLang } from '../../context/language'
import { useSelector } from 'react-redux'

const DownloadApp = () => {
    const { lang } = useLang()
    const { home } = useSelector(state => state.home)

    return (
        <section className='download-app'>
            <div className="container">
                <div className="image">
                    <img src="/imgs/downloadApp.png" alt="" />
                </div>
                {
                    <>
                        <h4>{lang ? home[0]?.ar[0]?.app_section_title : home[0]?.en[0]?.app_section_title}</h4>
                        <p>{lang ? home[0]?.ar[0]?.app_section_desc : home[0]?.en[0]?.app_section_desc}</p>
                    </>
                }
                <div className="download-btn">
                    <a href={home[0]?.google_play_link} className='bt'>
                        <div className="icon">
                            <svg id="google-play-store-svgrepo-com_1_" data-name="google-play-store-svgrepo-com (1)" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 34.467 36.929">
                                <path id="Path_27550" data-name="Path 27550" d="M19.933,17.618l5.17-5.539L5.039,1.246C4.915,1.123,4.669,1.123,4.3,1Z" transform="translate(-1.469 -1)" fill="#fff" />
                                <path id="Path_27551" data-name="Path 27551" d="M24.086,23.31l7.263-3.939A2.452,2.452,0,0,0,32.7,17.155a2.325,2.325,0,0,0-1.354-2.216L24.086,11,18.3,17.155Z" transform="translate(1.765 1.31)" fill="#fff" />
                                <path id="Path_27552" data-name="Path 27552" d="M2.492,1.9A1.851,1.851,0,0,0,2,3.254V35.259a2.366,2.366,0,0,0,.492,1.477l16.249-17.48Z" transform="translate(-2 -0.792)" fill="#fff" />
                                <path id="Path_27553" data-name="Path 27553" d="M19.933,17.5,4.3,34.118a1.75,1.75,0,0,0,.739-.246L25.1,23.039Z" transform="translate(-1.469 2.811)" fill="#fff" />
                            </svg>
                        </div>
                        {lang ? 'متجر بلاي' : 'Google play'}
                    </a>
                    <a href={home[0]?.app_store_link} className='bt'>
                        <div className="icon">
                            <svg id="Apple" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 31.858 39.334">
                                <g id="Stroke-82" transform="translate(0 10.538)">
                                    <path id="Path_27588" data-name="Path 27588" d="M12.666,36.3h0c-3.186,0-5.392-2.818-6.985-5.269C1.638,24.778.78,16.935,3.721,12.4a10.116,10.116,0,0,1,8.21-4.9,12.368,12.368,0,0,1,4.044.858,6.585,6.585,0,0,0,2.573.613,5.468,5.468,0,0,0,2.206-.613A12.576,12.576,0,0,1,25.042,7.5a9.91,9.91,0,0,1,7.475,3.921.953.953,0,0,1,.245.98,1.418,1.418,0,0,1-.613.858,5.959,5.959,0,0,0-3.186,5.882,6.1,6.1,0,0,0,4.044,5.514,1.1,1.1,0,0,1,.735.735,1.353,1.353,0,0,1,0,.98,23.945,23.945,0,0,1-2.328,4.289c-1.838,2.7-4.044,5.514-6.985,5.514a6.066,6.066,0,0,1-2.941-.735,5.7,5.7,0,0,0-2.941-.735,6.474,6.474,0,0,0-2.941.735A4.936,4.936,0,0,1,12.666,36.3Z" transform="translate(-1.976 -7.5)" fill="#fff" />
                                </g>
                                <g id="Stroke-83" transform="translate(14.028 0)">
                                    <path id="Path_27589" data-name="Path 27589" d="M14.988,9.683h-.245a1.319,1.319,0,0,1-1.225-.98,8.362,8.362,0,0,1,1.961-6.494A9.557,9.557,0,0,1,21.6-1.1,1.313,1.313,0,0,1,22.952,0,8.232,8.232,0,0,1,20.992,6.5h0A7.615,7.615,0,0,1,14.988,9.683Z" transform="translate(-13.424 1.1)" fill="#fff" />
                                </g>
                            </svg>
                        </div>
                        {lang ? 'متجر أبل' : 'App store'}
                    </a>
                </div>
            </div>
        </section>
    )
}

export default DownloadApp