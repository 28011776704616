import { createContext, useContext, useState } from "react";

const LangaugeContext = createContext()

export const LanguageProvider = ({ children }) => {
    const [lang, setLang] = useState(false)

    return <LangaugeContext.Provider value={{ lang, setLang }}>
        {children}
    </LangaugeContext.Provider>
}

export const useLang = () => {
    return useContext(LangaugeContext)
}