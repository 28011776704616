import React, { useEffect } from 'react'
import MainTitle from '../MainTitle/MainTitle'
import { useDispatch, useSelector } from 'react-redux'
import { getFaqs } from '../../redux/faqSlice'
import Spinner from '../Spinner/Spinner'
import { useLang } from '../../context/language'

const QuickAnswer = () => {
    const { lang } = useLang()
    const dispatch = useDispatch()
    const { loading, faqs } = useSelector(state => state.faq)
    const { home } = useSelector(state => state.home)
    useEffect(() => {
        faqs.length === 0 && dispatch(getFaqs())
    }, [dispatch, faqs])

    return (
        <section className='quick-answer' id='question'>
            <div className="container">
                {
                    <MainTitle title={lang ? home[0]?.ar[0]?.faq_title : home[0]?.en[0]?.faq_title}
                        description={lang ? home[0]?.ar[0]?.faq_desc : home[0]?.en[0]?.faq_desc} />
                }
                <div className="questiones">
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        {
                            loading ? <Spinner /> :
                                faqs.map(faq => (
                                    <div className="accordion-item" key={faq.id} style={lang ? { direction: 'rtl' } : {}}>
                                        <h2 className="accordion-header" id={`flush-heading${faq.id}`}>
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${faq.id}`} aria-expanded="false" aria-controls="flush-collapseTwo">
                                                {
                                                    lang ? faq.ar[0]?.title : faq.en[0]?.question
                                                }
                                            </button>
                                        </h2>
                                        <div id={`flush-collapse${faq.id}`} className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <p>
                                                    {
                                                        lang ? faq.ar[0]?.desc : faq.en[0]?.answer
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                        {/* <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in ?
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...</p>
                                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in ?
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...</p>
                                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in ?
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...</p>
                                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in ?
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...</p>
                                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default QuickAnswer