import React from 'react'

const MainTitle = ({ title, description }) => {
    return (
        <>
            <h1 className='main-title'>{title}</h1>
            <p className='main-title-description'>{description}</p>
        </>
    )
}

export default MainTitle