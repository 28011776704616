import React, { useEffect, useState } from 'react'
import MainTitle from '../MainTitle/MainTitle'
import { menu } from './menuItem'
import { useLang } from '../../context/language'
import { useDispatch, useSelector } from 'react-redux'
import { getOurDesign } from '../../redux/ourDesignSlice'
import Spinner from '../Spinner/Spinner'

const OurDesign = () => {
    const { lang } = useLang()
    const { home } = useSelector(state => state.home)
    const { loading, ourDesigns } = useSelector(state => state.ourDesign)
    const dispatch = useDispatch()
    const [menuItem, setMenuItem] = useState(menu)
    const [images, setImages] = useState([])
    const [size, setSize] = useState(6)

    const handleFilter = id => {
        const newMenu = menuItem.map(item => {
            if (item.id === id) {
                item.active = true
                const newImages = ourDesigns.filter(img => img.en[0].category_ID === item.id)
                setImages(newImages)
                setSize(6)
            } else {
                item.active = false
            }
            return item
        })
        setMenuItem(newMenu)
    }

    const handleMore = () => {
        setSize(prev => prev + 6)
    }


    useEffect(() => {
        if (ourDesigns.length === 0) {
            dispatch(getOurDesign())
        } else {
            setImages(ourDesigns.filter(img => img.en[0].category_ID === "7"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ourDesigns])


    return (
        <section className='our-design' id='design' style={lang ? { direction: 'rtl' } : {}}>
            <div className="container">
                {
                    <MainTitle title={lang ? home[0]?.ar[0]?.our_design_title : home[0]?.en[0]?.our_design_title}
                        description={lang ? home[0]?.ar[0]?.our_design_desc : home[0]?.en[0]?.our_design_desc} />
                }
                <ul className="menu-item">
                    {
                        menu.map(item => (
                            <li key={item.id} className={item.active ? 'active' : ''} onClick={() => handleFilter(item.id)}>
                                <div className="icon">
                                    {item.icon}
                                </div>
                                <h6>{lang ? item.name_ar : item.name}</h6>
                            </li>
                        ))
                    }
                </ul>
                <div className="items">
                    <div className="row">
                        {
                            loading ? <Spinner /> :
                                images.slice(0, size).map(img => (
                                    <div className='col-md-4' key={img.id}>
                                        <div className="box">
                                            <img src={`http://hashemceramic.com/${img.img}`} alt="" />
                                            <div className="text">
                                                <h3>{lang ? img?.ar[0]?.title : img?.en[0]?.title}</h3>
                                                <h6>{lang ? img?.ar[0]?.title2 : img?.en[0]?.title2}</h6>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
            {
                images.length > size && <button className='more' onClick={() => handleMore()}>
                    {
                        lang ? 'أكثر' : 'MORE'
                    }
                </button>

            }
        </section>
    )
}

export default OurDesign