import React, { useState } from 'react'
import { useLang } from '../../context/language'

const Navbar = () => {
    const [fixedTop, setFixedTop] = useState(false)
    window.onscroll = () => {
        if (window.scrollY >= 270) {
            setFixedTop(true)
        } else {
            setFixedTop(false)
        }
    }
    const { lang, setLang } = useLang()
    const handleLang = () => {
        setLang(prev => !prev)
        if (lang) {
            window.localStorage.setItem('lang', 'en')
        } else {
            window.localStorage.setItem('lang', 'ar')
        }
    }

    const handleClose = () => {
        setTimeout(() => {
            if (document.querySelector(".offcanvas.show .btn-close")) {
                document.querySelector(".offcanvas.show .btn-close").click()
            }
        }
            , 800)
    }
    return (
        <header>
            <div id="nav-container" className={fixedTop ? "nav-container fixed-top" : "nav-container"}>
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light bg-transparent">

                        <a className="navbar-brand logo" href="#home">
                            <img src="/imgs/logo.png" alt="" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbarExample-expand-xxl" aria-controls="offcanvasNavbarExample-expand-xxl">
                            <span className="navbar-toggler-icon" data-bs-target="#offcanvasNavbarExample-expand-xxl"></span>
                        </button>
                        <div className="offcanvas offcanvas-start" data-bs-hideresize="true" id="offcanvasNavbarExample-expand-xxl" aria-labelledby="offcanvasNavbarExample-expand-xxlLabel">
                            <div className="offcanvas-header">
                                <a className="navbar-brand logo" href="#home">
                                    <img src="/imgs/logo.png" alt="" />
                                </a>
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body d-lg-flex justify-content-center">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#home" onClick={handleClose}>{lang ? 'الرئيسية' : "Home"}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#product" onClick={handleClose}>{lang ? "مجموعة" : 'Collection'}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#design" onClick={handleClose}>{lang ? "تصاميم" : 'design'}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#question" onClick={handleClose}>{lang ? "اسئلة الشائعة" : "FAQ"}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#advice" onClick={handleClose}>{lang ? 'نصائح' : 'ADVICE'}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#brunches" onClick={handleClose}>{lang ? 'فروع' : 'BRANCHES'}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#about-us" onClick={handleClose}>{lang ? 'من نحن' : 'about us'}</a>
                                    </li>
                                    <li className="nav-item mobile">
                                        <button className="lang" onClick={handleLang}>
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24.828 24.828">
                                                    <path id="global-svgrepo-com"
                                                        d="M15.086,2.672h-.03a12.413,12.413,0,0,0,0,24.826h.03a12.414,12.414,0,0,0,0-24.828Zm.5,7.429a18.268,18.268,0,0,0,3.83-.51,22.207,22.207,0,0,1,.639,5H15.582V10.1Zm0-.993V3.744c1.338.371,2.695,2.155,3.559,4.892A17.232,17.232,0,0,1,15.582,9.108ZM14.59,3.732V9.108A17.19,17.19,0,0,1,11,8.628c.872-2.753,2.24-4.544,3.588-4.9Zm0,6.368V14.59h-4.5a22.188,22.188,0,0,1,.64-5,18.3,18.3,0,0,0,3.86.515ZM9.09,14.59H3.678A11.372,11.372,0,0,1,6.261,7.845a18.2,18.2,0,0,0,3.508,1.48A23.405,23.405,0,0,0,9.09,14.59Zm0,.993a23.4,23.4,0,0,0,.678,5.263,18.291,18.291,0,0,0-3.508,1.481,11.36,11.36,0,0,1-2.583-6.744Zm1,0h4.5v4.484a18.256,18.256,0,0,0-3.861.517,22.216,22.216,0,0,1-.639-5Zm4.5,5.476V26.44c-1.349-.352-2.719-2.145-3.59-4.9A17.2,17.2,0,0,1,14.59,21.058Zm.993,5.371v-5.37a17.3,17.3,0,0,1,3.56.473C18.278,24.272,16.922,26.059,15.582,26.429Zm0-6.363V15.582h4.469a22.22,22.22,0,0,1-.637,4.993,18.224,18.224,0,0,0-3.831-.51Zm5.468-4.484h5.444a11.363,11.363,0,0,1-2.583,6.743,18.377,18.377,0,0,0-3.537-1.489,23.389,23.389,0,0,0,.676-5.253Zm0-.993a23.424,23.424,0,0,0-.677-5.259,18.333,18.333,0,0,0,3.535-1.488,11.366,11.366,0,0,1,2.586,6.747H21.05Zm2.174-7.505a17.349,17.349,0,0,1-3.12,1.29,11.142,11.142,0,0,0-2.359-4.394,11.427,11.427,0,0,1,5.48,3.1ZM12.388,3.99A11.147,11.147,0,0,0,10.04,8.365,17.3,17.3,0,0,1,6.948,7.084a11.418,11.418,0,0,1,5.44-3.094ZM6.944,23.084A17.264,17.264,0,0,1,10.039,21.8a11.145,11.145,0,0,0,2.349,4.38,11.416,11.416,0,0,1-5.443-3.1Zm10.8,3.106a11.134,11.134,0,0,0,2.361-4.4,17.23,17.23,0,0,1,3.121,1.292,11.408,11.408,0,0,1-5.482,3.105Z"
                                                        transform="translate(-2.672 -2.672)" fill="#202020" />
                                                </svg>
                                            </span> {
                                                lang ? 'EN' : 'AR'
                                            }
                                        </button>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="links-nav">
                            <button className="lang del-mobile" onClick={handleLang}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24.828 24.828">
                                        <path id="global-svgrepo-com"
                                            d="M15.086,2.672h-.03a12.413,12.413,0,0,0,0,24.826h.03a12.414,12.414,0,0,0,0-24.828Zm.5,7.429a18.268,18.268,0,0,0,3.83-.51,22.207,22.207,0,0,1,.639,5H15.582V10.1Zm0-.993V3.744c1.338.371,2.695,2.155,3.559,4.892A17.232,17.232,0,0,1,15.582,9.108ZM14.59,3.732V9.108A17.19,17.19,0,0,1,11,8.628c.872-2.753,2.24-4.544,3.588-4.9Zm0,6.368V14.59h-4.5a22.188,22.188,0,0,1,.64-5,18.3,18.3,0,0,0,3.86.515ZM9.09,14.59H3.678A11.372,11.372,0,0,1,6.261,7.845a18.2,18.2,0,0,0,3.508,1.48A23.405,23.405,0,0,0,9.09,14.59Zm0,.993a23.4,23.4,0,0,0,.678,5.263,18.291,18.291,0,0,0-3.508,1.481,11.36,11.36,0,0,1-2.583-6.744Zm1,0h4.5v4.484a18.256,18.256,0,0,0-3.861.517,22.216,22.216,0,0,1-.639-5Zm4.5,5.476V26.44c-1.349-.352-2.719-2.145-3.59-4.9A17.2,17.2,0,0,1,14.59,21.058Zm.993,5.371v-5.37a17.3,17.3,0,0,1,3.56.473C18.278,24.272,16.922,26.059,15.582,26.429Zm0-6.363V15.582h4.469a22.22,22.22,0,0,1-.637,4.993,18.224,18.224,0,0,0-3.831-.51Zm5.468-4.484h5.444a11.363,11.363,0,0,1-2.583,6.743,18.377,18.377,0,0,0-3.537-1.489,23.389,23.389,0,0,0,.676-5.253Zm0-.993a23.424,23.424,0,0,0-.677-5.259,18.333,18.333,0,0,0,3.535-1.488,11.366,11.366,0,0,1,2.586,6.747H21.05Zm2.174-7.505a17.349,17.349,0,0,1-3.12,1.29,11.142,11.142,0,0,0-2.359-4.394,11.427,11.427,0,0,1,5.48,3.1ZM12.388,3.99A11.147,11.147,0,0,0,10.04,8.365,17.3,17.3,0,0,1,6.948,7.084a11.418,11.418,0,0,1,5.44-3.094ZM6.944,23.084A17.264,17.264,0,0,1,10.039,21.8a11.145,11.145,0,0,0,2.349,4.38,11.416,11.416,0,0,1-5.443-3.1Zm10.8,3.106a11.134,11.134,0,0,0,2.361-4.4,17.23,17.23,0,0,1,3.121,1.292,11.408,11.408,0,0,1-5.482,3.105Z"
                                            transform="translate(-2.672 -2.672)" fill="#202020" />
                                    </svg>
                                </span> {
                                    lang ? 'EN' : 'AR'
                                }
                            </button>
                            <a className="contact" href="#contact">
                                {lang ? 'تواصل' : 'Contact'}
                            </a>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default Navbar