import { configureStore } from "@reduxjs/toolkit";
import carouselSlice from "./carouselSlice";
import faqSlice from "./faqSlice";
import homeApiSlice from "./homeApiSlice";
import newProductSlice from "./newProductSlice";
import newProductTypeSlice from "./newProductSliceType";
import ourDesignSlice from "./ourDesignSlice";
import adviceSlice from "./adviceSlice";
import branchesSlice from "./branchesSlice";

const store = configureStore({
  reducer: {
    newProducts: newProductSlice,
    newProductTypes: newProductTypeSlice,
    carousels: carouselSlice,
    faq: faqSlice,
    home: homeApiSlice,
    ourDesign: ourDesignSlice,
    advice: adviceSlice,
    branches: branchesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
