import React, { useEffect, useState, } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useDispatch, useSelector } from 'react-redux'
import { getCarousels } from '../../redux/carouselSlice'
import Spinner from '../Spinner/Spinner';
import { useLang } from '../../context/language';


const Landing = () => {
    const { lang } = useLang()
    const dispatch = useDispatch()
    const [state, setState] = useState(false)
    const { loading, carousels } = useSelector(state => state.carousels)
    //Owl Carousel Settings
    const options = {
        loop: true,
        margin: 30,
        dots: true,
        responsiveClass: true,
        nav: false,
        autoplay: true,
        smartSpeed: 1000,
        lazyLoad: true,
        items: 1,
        // autoplayHoverPause: true
    };

    useEffect(() => {
        if (carousels.length === 0) {
            dispatch(getCarousels())
        }
    }, [carousels, dispatch])


    useEffect(() => {
        setState(lang)
    }, [lang])


    return (
        <section className='landing' states={`${state}`} id='home'>
            {
                loading ? <Spinner /> :
                    <OwlCarousel className="slider-items owl-carousel" {...options}>
                        {
                            carousels.map(carousel => (<div className="item" key={carousel.id}>
                                <img src={`http://hashemceramic.com/${carousel.img}`} alt="" />
                                <div className="text">
                                    <h1 className="title">{lang ? carousel.ar[0]?.title : carousel.en[0]?.title}</h1>
                                </div>
                                <div className="plus-icon" onClick={(e) => { e.currentTarget.parentNode.children[3].classList.toggle('open'); }}>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 33.805 33.805">
                                        <path id="primary-stroke" d="M5,17.8H30.6M17.8,5V30.6" transform="matrix(0.966, -0.259, 0.259, 0.966, -4.899, 4.315)" fill="none" stroke="#cb9954" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    </svg> */}
                                    ?
                                </div>
                                <div className='plus-icon-contant'>
                                    <h3>{lang ? carousel.ar[0]?.title2 : carousel.en[0]?.title2}</h3>
                                    <p>{lang ? carousel.ar[0]?.desc : carousel.en[0]?.desc}</p>
                                </div>
                            </div>))
                        }
                    </OwlCarousel>
            }
        </section>
    )
}

export default Landing