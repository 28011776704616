import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: true,
  carousels: [],
  error: "",
};

export const getCarousels = createAsyncThunk(
  "carousel/getCarousels",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const fetchCarousels = await axios.get(
        "http://hashemceramic.com/marble/slider_api"
      );
      const data = await fetchCarousels.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const carouselSlice = createSlice({
  name: "carousel",
  initialState,
  extraReducers: {
    [getCarousels.pending]: (state) => {
      state.loading = true;
      state.error = "";
    },
    [getCarousels.fulfilled]: (state, action) => {
      state.loading = false;
      state.carousels = action.payload;
    },
    [getCarousels.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default carouselSlice.reducer;
