import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProductsType } from '../redux/newProductSliceType'
import { getProducts } from '../redux/newProductSlice'
import Spinner from '../components/Spinner/Spinner'
import OurProduct from '../components/OurProduct/OurProduct'
import NewProdict from '../components/NewProdict/NewProdict'

const Product = () => {
    const dispatch = useDispatch()
    const { loading, newProduct } = useSelector(state => state.newProducts)
    const { loading_newProductTypes, newProductTypes } = useSelector(state => state.newProductTypes)


    useEffect(() => {
        if (newProductTypes.length === 0) {
            dispatch(getProductsType())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newProductTypes])



    useEffect(() => {
        if (newProduct.length === 0) {
            dispatch(getProducts())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newProduct])


    return (
        <>
            {
                loading_newProductTypes ? <Spinner relative={true} /> :
                    loading ? <Spinner relative={true} /> :
                        <>
                            <NewProdict newProduct={newProduct} newProductTypes={newProductTypes} />
                            <OurProduct newProduct={newProduct} newProductTypes={newProductTypes} />
                        </>
            }
        </>
    )
}

export default Product