import React, { useEffect, useState } from 'react'
import MainTitle from '../MainTitle/MainTitle'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useLang } from '../../context/language';
import { useDispatch, useSelector } from 'react-redux';
import { getAdvice } from '../../redux/adviceSlice';
import Spinner from '../Spinner/Spinner';

const HishamAdvice = () => {

    const { lang } = useLang()
    const { home } = useSelector(state => state.home)
    const { loading, advice } = useSelector(state => state.advice)
    const dispatch = useDispatch()
    const [state, setState] = useState(false)
    let count = 1


    //Owl Carousel Settings
    const options = {
        loop: true,
        margin: 30,
        dots: false,
        responsiveClass: true,
        nav: false,
        autoplay: false,
        smartSpeed: 1000,
        lazyLoad: true,
        navText: [
            '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 27.963 21.732"><path id="arrow-right-svgrepo-com" d="M.432,18.358,10,8.618a1.194,1.194,0,0,1,1.689,1.689L4.035,17.964h22.8a1.143,1.143,0,0,1,1.126,1.182,1.155,1.155,0,0,1-1.182,1.182H3.979l7.713,7.657a1.224,1.224,0,0,1,0,1.689,1.146,1.146,0,0,1-.845.338A1.241,1.241,0,0,1,10,29.675L.319,19.991a1.079,1.079,0,0,1,.113-1.633Z" transform="translate(0 -8.28)"/></svg>',
            '<svg xmlns="http://www.w3.org/2000/svg" width="27.963" height="21.732" viewBox="0 0 27.963 21.732"><path id="arrow-right-svgrepo-com" d="M27.531,18.358,17.96,8.618a1.194,1.194,0,0,0-1.689,1.689l7.657,7.657H1.126A1.143,1.143,0,0,0,0,19.146a1.155,1.155,0,0,0,1.182,1.182h22.8l-7.713,7.657a1.224,1.224,0,0,0,0,1.689,1.146,1.146,0,0,0,.845.338,1.241,1.241,0,0,0,.845-.338l9.684-9.684a1.079,1.079,0,0,0-.113-1.633Z" transform="translate(0 -8.28)" fill="#c19c63"/></svg>',
        ],
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            },
        },
    };

    useEffect(() => {
        advice.length === 0 && dispatch(getAdvice())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advice])

    useEffect(() => {
        setState(lang)
    }, [lang])
    return (
        <section className='hisham-advice' id='advice' states={`${state}`}>
            <div className="container">
                {
                    <MainTitle title={lang ? home[0]?.ar[0]?.dvice_title : home[0]?.en[0]?.dvice_title}
                        description={lang ? home[0]?.ar[0]?.advice_desc : home[0]?.en[0]?.advice_desc} />
                }
                {
                    loading ? <Spinner /> :
                        <OwlCarousel className="slider-items owl-carousel" {...options}>
                            {
                                advice.map(item => <div className="item" key={item.id}>
                                    <img src={`/imgs/advice-${count === 4 ? 1 : count++}.png`} alt="" />
                                    <div className="text">
                                        <h4 className="title">
                                            {lang ? item?.ar[0]?.title : item?.en[0]?.question}
                                        </h4>
                                        <p>{lang ? item?.ar[0]?.desc : item?.en[0]?.answer}</p>
                                    </div>
                                </div>)
                            }
                            {/* <div className="item">
                                <img src="/imgs/advice-2.png" alt="" />
                                <div className="text">
                                    <h4 className="title">title here</h4>
                                    <p>Lorem ipsum, or lipsum as it is
                                        sometimes known, is dummy
                                        text used in laying out print,
                                        graphic or web designs. The
                                        passage is attributed to an
                                        unknown ...</p>
                                </div>
                            </div>
                            <div className="item">
                                <img src="/imgs/advice-3.png" alt="" />
                                <div className="text">
                                    <h4 className="title">title here</h4>
                                    <p>Lorem ipsum, or lipsum as it is
                                        sometimes known, is dummy
                                        text used in laying out print,
                                        graphic or web designs. The
                                        passage is attributed to an
                                        unknown ...</p>
                                </div>
                            </div> */}
                        </OwlCarousel>
                }
            </div>
        </section>
    )
}

export default HishamAdvice