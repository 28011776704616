import React, { useEffect, useState } from 'react'
import MainTitle from '../MainTitle/MainTitle'
import { useLang } from '../../context/language'
import { useSelector } from 'react-redux'

const OurProduct = ({ newProduct, newProductTypes }) => {
    const { lang } = useLang()
    const [menuItem, setMenuItem] = useState([])
    const [images, setImages] = useState(newProduct.filter(img => img.new === 'False'))
    const [size, setSize] = useState(6)
    const { home } = useSelector(state => state.home)


    const handleFilter = id => {
        const newMenu = menuItem.map(item => {
            if (item.id === id) {
                item.active = true
                const newImages = newProduct.filter(img => img.en[0].type === item.en[0].type && img.new === 'False')
                setImages(newImages)
                setSize(6)
            } else {
                item.active = false
            }
            return item
        })
        setMenuItem(newMenu)
    }

    const handleMore = () => {
        setSize(prev => prev + 6)
    }

    useEffect(() => {
        const ProductTypes = newProductTypes.map(item => ({ ...item, active: false }))
        ProductTypes[0].active = true
        setMenuItem(ProductTypes)

        setImages(newProduct.filter(img => img.en[0]?.type === newProductTypes[0]?.en[0].type && img.new === 'False'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <section className='new-prodict' style={lang ? { direction: 'rtl' } : {}}>
            <div className="container">
                {
                    <MainTitle title={lang ? home[0]?.ar[0]?.our_product_title : home[0]?.en[0]?.our_product_title}
                        description={lang ? home[0]?.ar[0]?.our_product_desc : home[0]?.en[0]?.our_product_desc} />
                }
                <ul className="menu-item">
                    {
                        menuItem.map(item => (
                            <li key={item.id} className={item.active ? 'active' : ''} onClick={() => handleFilter(item.id)}>{lang ? item.ar[0]?.type : item.en[0]?.type}</li>
                        ))
                    }
                </ul>
                <div className="items">
                    <div className="row">
                        {
                            images.slice(0, size).map(imag => (
                                <div className='col-md-4' key={imag.id}>
                                    <a className='box' href={`http://hashemceramic.com/${imag.pdf}`}>
                                        <img src={`http://hashemceramic.com/${imag.img}`} alt="" />
                                        <div className="caption">
                                            <h3>{lang ? imag.ar[0]?.title : imag.en[0]?.title}</h3>
                                        </div>
                                    </a>
                                </div>
                            ))
                        }
                    </div>
                    {
                        images.length > size && <button className='more' onClick={() => handleMore()}>
                            {
                                lang ? 'أكثر' : 'MORE'
                            }
                        </button>

                    }
                    {/* <div className="arrows">
                        <button className="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 27.963 21.732">
                                <path id="arrow-right-svgrepo-com" d="M.432,18.358,10,8.618a1.194,1.194,0,0,1,1.689,1.689L4.035,17.964h22.8a1.143,1.143,0,0,1,1.126,1.182,1.155,1.155,0,0,1-1.182,1.182H3.979l7.713,7.657a1.224,1.224,0,0,1,0,1.689,1.146,1.146,0,0,1-.845.338A1.241,1.241,0,0,1,10,29.675L.319,19.991a1.079,1.079,0,0,1,.113-1.633Z" transform="translate(0 -8.28)" />
                            </svg>
                        </button>
                        <button className="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 27.963 21.732">
                                <path id="arrow-right-svgrepo-com" d="M27.531,18.358,17.96,8.618a1.194,1.194,0,0,0-1.689,1.689l7.657,7.657H1.126A1.143,1.143,0,0,0,0,19.146a1.155,1.155,0,0,0,1.182,1.182h22.8l-7.713,7.657a1.224,1.224,0,0,0,0,1.689,1.146,1.146,0,0,0,.845.338,1.241,1.241,0,0,0,.845-.338l9.684-9.684a1.079,1.079,0,0,0-.113-1.633Z" transform="translate(0 -8.28)" fill="#c19c63" />
                            </svg>
                        </button>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default OurProduct