import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading_newProductTypes: true,
  newProductTypes: [],
  error: "",
};

export const getProductsType = createAsyncThunk(
  "newProductTypes/getNewProductTypes",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const fetchNewProducts = await axios.get(
        "http://hashemceramic.com/marble/types_api"
      );
      const data = await fetchNewProducts.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const newProductTypeSlice = createSlice({
  name: "newProductTypes",
  initialState,
  extraReducers: {
    [getProductsType.pending]: (state) => {
      state.loading_newProductTypes = true;
      state.error = "";
    },
    [getProductsType.fulfilled]: (state, action) => {
      state.loading_newProductTypes = false;
      state.newProductTypes = action.payload;
    },
    [getProductsType.rejected]: (state, action) => {
      state.loading_newProductTypes = false;
      state.error = action.payload;
    },
  },
});

export default newProductTypeSlice.reducer;
