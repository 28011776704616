import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLang } from '../../context/language'
import MainTitle from '../MainTitle/MainTitle'

const ContactUs = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const { lang } = useLang()
    const { home } = useSelector(state => state.home)
    const form = useRef()

    return (
        <section className='contact-us' id='contact' style={lang ? { direction: 'rtl' } : {}}>
            <div className="container">
                {
                    <MainTitle title={lang ? home[0]?.ar[0]?.contact_us_title : home[0]?.en[0]?.contact_us_title}
                        description={lang ? home[0]?.ar[0]?.contact_us_desc : home[0]?.en[0]?.contact_us_desc} />
                }
                <form className="contact" ref={form} action={`mailto:Hashem_est@hotmail.com?body=${message}`} method='POST'>
                    <input type="text"
                        className='form-control'
                        name="name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder={lang ? 'الاسم' : 'Name'}
                    />
                    <input type="text"
                        className='form-control'
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        placeholder={lang ? 'البريد الالكتروني' : 'Email'}
                    />
                    <textarea
                        className='form-control'
                        value={message}
                        name="message"
                        onChange={e => setMessage(e.target.value)}
                        placeholder={lang ? 'رسالة' : 'Massege'}
                    ></textarea>
                    <button className='send' type='submit'>{
                        lang ? 'إرسال' : 'Send'
                    }</button>
                </form>
            </div>
        </section>
    )
}

export default ContactUs