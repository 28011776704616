import React, { useEffect, useState } from 'react'
import MainTitle from '../MainTitle/MainTitle'
import { useLang } from '../../context/language'
import { useSelector } from 'react-redux'

const NewProdict = ({ newProduct, newProductTypes }) => {
    const { lang } = useLang()
    const [menuItem, setMenuItem] = useState([])
    const [images, setImages] = useState(newProduct.filter(img => img.new === 'True'))
    const [size, setSize] = useState(6)
    const { home } = useSelector(state => state.home)


    const handleFilter = id => {
        const newMenu = menuItem.map(item => {
            if (item.id === id) {
                item.active = true
                const newImages = newProduct.filter(img => img.en[0].type === item.en[0].type && img.new === 'True')
                setImages(newImages)
                setSize(6)
            } else {
                item.active = false
            }
            return item
        })
        setMenuItem(newMenu)
    }

    const handleMore = () => {
        setSize(prev => prev + 6)
    }

    useEffect(() => {
        const ProductTypes = newProductTypes.map(item => ({ ...item, active: false }))
        ProductTypes[0].active = true
        setMenuItem(ProductTypes)

        setImages(newProduct.filter(img => img.en[0]?.type === newProductTypes[0]?.en[0].type && img.new === 'True'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <section className='new-prodict' id='product' style={lang ? { direction: 'rtl' } : {}}>
                <div className="container">
                    {
                        <MainTitle title={lang ? home[0]?.ar[0]?.new_product_title : home[0]?.en[0]?.new_product_title}
                            description={lang ? home[0]?.ar[0]?.new_product_desc : home[0]?.en[0]?.new_product_desc} />
                    }
                    <ul className="menu-item">
                        {
                            menuItem.map(item => (
                                <li key={item.id} className={item.active ? 'active' : ''} onClick={() => handleFilter(item.id)}>{lang ? item.ar[0]?.type : item.en[0]?.type}</li>
                            ))
                        }
                    </ul>
                    <div className="items">
                        <div className="row">
                            {
                                images.slice(0, size).map(imag => (
                                    <div className='col-md-4' key={imag.id}>
                                        <a className='box' href={`http://hashemceramic.com/${imag.pdf}`}>
                                            <img src={`http://hashemceramic.com/${imag.img}`} alt="" />
                                            <div className="caption">
                                                <h3>{lang ? imag.ar[0]?.title : imag.en[0]?.title}</h3>
                                            </div>
                                        </a>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {
                        images.length > size && <button className='more' onClick={() => handleMore()}>
                            {
                                lang ? 'أكثر' : 'MORE'
                            }
                        </button>

                    }
                </div>
            </section>
        </>
    )
}

export default NewProdict