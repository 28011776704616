import { useEffect } from "react";
import AboutUs from "./components/AboutUs/AboutUs";
import Branches from "./components/Branches/Branches";
import ContactUs from "./components/ContactUs/ContactUs";
import DownloadApp from "./components/DownloadApp/DownloadApp";
import Footer from "./components/Footer/Footer";
import HishamAdvice from "./components/HishamAdvice/HishamAdvice";
import Landing from "./components/Landing/Landing";
import Navbar from "./components/Navbar.jsx/Navbar";
import OurDesign from "./components/OurDesign/OurDesign";
import QuickAnswer from "./components/QuickAnswer/QuickAnswer";
import { useDispatch, useSelector } from "react-redux";
import { getHome } from "./redux/homeApiSlice";
import Spinner from "./components/Spinner/Spinner";
import Product from "./container/Product";
import { useLang } from "./context/language";



function App() {
  const dispatch = useDispatch()
  const { loading, home } = useSelector(state => state.home)
  const { setLang } = useLang()

  useEffect(() => {
    if (window.localStorage.getItem('lang') === 'ar') {
      setLang(true)
    } else {
      setLang(false)
    }
    if (home.length === 0) {
      dispatch(getHome())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [home])
  return (
    <div className="App">
      {
        loading ? <Spinner />
          :
          <>
            <Navbar />
            <Landing />
            <Product />
            <OurDesign />
            <DownloadApp />
            <AboutUs />
            <QuickAnswer />
            <HishamAdvice />
            <Branches />
            <ContactUs />
            <Footer />
          </>
      }
    </div>
  );
}

export default App;
