import React, { useEffect, useRef } from 'react'
import { useLang } from '../../context/language'
import { useSelector } from 'react-redux'

const AboutUs = () => {
    const { lang } = useLang()
    const paragraph = useRef(null)
    const { home } = useSelector(state => state.home)

    useEffect(() => {
        paragraph.current.innerHTML = lang ? home[0]?.ar[0]?.about_us_desc : home[0]?.en[0]?.about_us_desc
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className='about-us' style={lang ? { direction: 'rtl' } : {}} id='about-us'>
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        {
                            <>
                                <h1>{lang ? home[0]?.ar[0]?.about_us_title : home[0]?.en[0]?.about_us_title}</h1>
                                <p ref={paragraph}></p>
                            </>
                        }
                    </div>
                    <div className="col-md-5 position-relative mob">
                        <div className="image">
                            <img src={`http://hashemceramic.com/${home[0]?.about_us_img}`} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs